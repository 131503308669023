.bounce2 {
    animation: bounce2 4s 6s ease infinite;
    -moz-animation: bounce2 4s 6s ease infinite;
    -webkit-animation: bounce2 4s 6s ease infinite;
    -o-animation: bounce2 4s 6s ease infinite;
    
  }
.bounce2:hover{
    animation-play-state: paused;
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    -o-animation-play-state: paused;
    opacity: 1;
    box-shadow: 0px 0px 50px var(--secondary-color);
}
  @keyframes bounce2 {
      0%, 30%, 45%, 100% {transform: translateY(0);}
      25% {transform: translateY(-30px);}
      40% {transform: translateY(-20px);}
  }
  @-moz-keyframes bounce2 {
      0%, 30%, 45%, 100% {-moz-transform: translateY(0);}
      25% {-moz-transform: translateY(-30px);}
      40% {-moz-transform: translateY(-20px);}
  }

.clickhere {
    padding: 0.5rem;
    border: solid 4px transparent;
    background: 
        /* linear-gradient(230deg, var(--secondary-color), var(--primary-color)) content-box, */
        linear-gradient(to bottom, var(--my-color), var(--my-color)) padding-box, 
        linear-gradient(230deg, var(--secondary-color), var(--primary-color)) border-box;
    opacity: 0.9;
    border-radius: 9999px;
    border: solid 4px transparent;
    box-shadow: 0px 0px 30px var(--secondary-color);
    /* border-left-color: var(--primary-color) ;
    border-bottom-color: var(--primary-color);
    border-top-color: var(--secondary-color);
    border-right-color: var(--secondary-color); */
}



.subtitle {
    background-image: linear-gradient(351deg, var(--secondary-color) 23%, var(--primary-color) 87%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;


}

.presentationhover {
    transition: all 150ms ease-in-out;
}


.presentation {
    background-image: linear-gradient(45deg, rgba(1,208,158,0.35) 0%, rgba(1,208,158,0.5) 40%, rgba(14,207,222,0.5) 70%, rgba(14,207,222,0.35) 100%);
  }  

.changementCouleurPresentation {
    animation: animateBg 3s linear infinite;
    -moz-animation: animateBg 3s linear infinite;
    -webkit-animation: animateBg 3s linear infinite;
    -o-animation: animateBg 3s linear infinite;
    background-image: linear-gradient(45deg,#01d0a0,#0ecfde,#01d0a0,#0ecfde);
    background-size: 300% 100%;
    opacity: 0.8;
    transition: all 150ms ease-in-out;
  }
  @keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }
  @-webkit-keyframes animateBg {
    0% { background-position: 0% 0%; }
    100% { background-position: 100% 0%; }
  }

.changementCouleurPresentation:hover {
opacity: 0.9;
}




.name-focus-in {
	-webkit-animation: text-focus-in 0.75s ease-out both;
	        animation: text-focus-in 0.75s ease-out both;
     -moz-animation: text-focus-in 0.75s ease-out both;
       -o-animation: text-focus-in 0.75s ease-out both;
}
.triangle-focus-in {
	-webkit-animation: text-focus-in 0.75s 0.5s ease-out both;
	        animation: text-focus-in 0.75s 0.5s ease-out both;
     -moz-animation: text-focus-in 0.75s 0.5s ease-out both;
       -o-animation: text-focus-in 0.75s 0.5s ease-out both;
}
.job-focus-in {
	-webkit-animation: text-focus-in 0.75s 1s ease-out both;
	        animation: text-focus-in 0.75s 1s ease-out both;
     -moz-animation: text-focus-in 0.75s 1s ease-out both;
       -o-animation: text-focus-in 0.75s 1s ease-out both;
}
.cat-focus-in {
	-webkit-animation: text-focus-in 1s 3s ease-out both;
	        animation: text-focus-in 1s 3s ease-out both;
     -moz-animation: text-focus-in 1s 3s ease-out both;
       -o-animation: text-focus-in 1s 3s ease-out both;
}
 @-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
    text-shadow: none;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
    text-shadow: none;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}



.text-flicker-in-glow {
	-webkit-animation: text-flicker-in-glow 3s 2s linear both;
	        animation: text-flicker-in-glow 3s 2s linear both;
  -moz-animation: text-flicker-in-glow 3s 2s linear both;
  -o-animation: text-flicker-in-glow 3s 2s linear both;

}
 @-webkit-keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
    text-shadow: none;
  }
  10% {
    text-shadow: none;
  }
  10.1% {
  }
  10.2% {
    text-shadow: none;
  }
  20% {
    text-shadow: none;
  }
  20.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  20.6% {
    text-shadow: none;
  }
  30% {
    text-shadow: none;
  }
  30.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  30.5% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  30.6% {
    text-shadow: none;
  }
  45% {
    text-shadow: none;
  }
  45.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  50% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  55% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  55.1% {
    text-shadow: none;
  }
  57% {
    text-shadow: none;
  }
  57.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  60% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  60.1% {
    text-shadow: none;
  }
  65% {
    text-shadow: none;
  }
  65.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  75% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  75.1% {
    text-shadow: none;
  }
  77% {
    text-shadow: none;
  }
  77.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  85% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  85.1% {
    text-shadow: none;
  }
  86% {
    text-shadow: none;
  }
  86.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  100% {
    text-shadow: 0 0 110px #01d0a0, 0 0 100px #0ecfde;
  }
}
@keyframes text-flicker-in-glow {
  0% {
    opacity: 0;
    text-shadow: none;
  }
  10% {
    text-shadow: none;
  }
  10.1% {
  }
  10.2% {
    text-shadow: none;
  }
  20% {
    text-shadow: none;
  }
  20.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  20.6% {
    text-shadow: none;
  }
  30% {
    text-shadow: none;
  }
  30.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  30.5% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  30.6% {
    text-shadow: none;
  }
  45% {
    text-shadow: none;
  }
  45.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  50% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  55% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  55.1% {
    text-shadow: none;
  }
  57% {
    text-shadow: none;
  }
  57.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  60% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  60.1% {
    text-shadow: none;
  }
  65% {
    text-shadow: none;
  }
  65.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  75% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  75.1% {
    text-shadow: none;
  }
  77% {
    text-shadow: none;
  }
  77.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  85% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  85.1% {
    text-shadow: none;
  }
  86% {
    text-shadow: none;
  }
  86.1% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
  100% {
    text-shadow: 0 0 10px #0ecfde, 0 0 20px #01d0a0;
  }
}

.animNeon {
  opacity: 0;
  animation: flashing 2s 2s infinite alternate;
  -ms-animation: flashing 2s 2s infinite alternate;
  -moz-animation: flashing 2s 2s infinite alternate;
  -webkit-animation: flashing 2s 2s infinite alternate;
  -o-animation: flashing 2s 2s infinite alternate;
}
    

@keyframes flashing {
    0%, 18%, 21%, 32%, 35%, 39%, 42%, 100% {
      opacity: 1;
      text-shadow:
        -10 0 16px #0ecfde,
        10 0 16px #01d0a0,
        -10 0 32px #0ecfde,
        10 0 32px #01d0a0,
    }
    20%, 34%, 41% {     
      opacity: 0;   
    }
}
@-webkit-keyframes flashing {
    0%, 18%, 21%, 32%, 35%, 39%, 42%, 100% {
      opacity: 1;
      text-shadow:
        -10 0 16px #0ecfde,
        10 0 16px #01d0a0,
        -10 0 32px #0ecfde,
        10 0 32px #01d0a0,
    }
    20%, 34%, 41% {     
      opacity: 0;   
    }
}

.imagehover {
  width: 100%;
  transition: all 0.5s ease;
}

.imagehover:hover {
  transform: scale(1.15);
}