html {
  scroll-behavior: smooth;
}

.navbar {
  background: var(--navbar-color);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  height: 64px;
  z-index: 1000;
}

.toolbar {
  height: 60px;
}

.menuButton {
  margin-right: 20px;
}

.linkBrand {
  display: "none";
}
.linkBrandSmall {
  display: "inline-block";
}

.tabs {
  height: 100%;
  color: aliceblue;
  height: 52px;
  overflow-x: visible;
}

.tab {
  height: 64px;
  padding: 0px 0px;
}

.tabFont {
  text-transform: none;
  text-decoration: none;
}

.drawerContainer {
  width: 256;
}
