.Card_Be {
    backdrop-filter: blur(6px);
    box-shadow: 1px 1px 0px 2px rgba(1,208,158,0.75), -1px -1px 0px 2px rgba(14,207,222,0.75);
    background: transparent;
    border-radius: 20px;
    transition: transform 0.1s;
    z-index: 500;
  }
  
  .Card_Be:hover {
    filter: blur(-2px);
    transform: scale(1.01);
  }
  
  .MiniCard {
    backdrop-filter: blur(6px);
    box-shadow: 1px 1px 0px 2px rgba(1,208,158,0.75), -1px -1px 0px 2px rgba(14,207,222,0.75);
    border-radius: 20px;
    background: transparent;
    transition: transform 0.1s;
    z-index: 500;
  
  }
  
  .MiniCard:hover {
    filter: blur(-2px);
    transform: scale(1.01);
  }

.Card_timeline {
    backdrop-filter: blur(6px);
    box-shadow: var(--shadowbox-color);
    background: var(--navbar-color);
    transition: all .2s ease-in-out;
}
.Card_timeline:hover {
    background: var(--navbar-color);
    
    
}
.Card_timeline:hover #contentcard {display: block; }
.timeline-css {
  backdrop-filter: blur(6px);
  box-shadow: var(--shadowbox-color);
}
.text_color_theme2 {
  color: var(--text-color-secondary);
}

#contentcard {
  display: none;
  
}

.vertical-timeline-element-content {
  box-shadow: 0 3px 0 #01d09e;
}

@media screen and (max-width: 1169px) {
  .vertical-timeline-element-content .vertical-timeline-element-date {
    /* padding-top: 0; */
    padding-bottom: 0;
    text-align: center;
  }
}
@media screen and (min-width: 1170px) {
  .vertical-timeline-element-content .vertical-timeline-element-date {
    /* padding-top: 0; */
    padding-bottom: 0;
    padding-left: 0%;
    padding-right: 0%;
  }
}

.BadgeTimeline {
  border-radius: 9999px;
  /* font-size: 10px; */
  background-color: var(--color-badge);
  padding-top: 0.125rem/* 2px */;
  padding-bottom: 0.125rem/* 2px */;
  padding-left: 0.5rem/* 8px */;
  padding-right: 0.5rem/* 8px */;
  --tw-backdrop-blur: blur(12px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.btnLinkDegree {
  border-radius: 0.375rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  box-shadow: var(--shadowbox-btn-color);
  color: #454549;
  opacity: 1;
}

.changementCouleur {
  animation: animateBg 3s linear infinite;
  background-image: linear-gradient(45deg,#01d0a0,#0ecfde,#01d0a0,#0ecfde);
  background-size: 300% 100%;
  -webkit-mask-image: linear-gradient(45deg,#FFF 25%,rgba(255,255,255,0.7) 50%,#FFF 75%);
  mask-image: linear-gradient(45deg,#FFF 25%,rgba(255,255,255,0.7) 50%,#FFF 75%);
  -webkit-mask-size: 800%;
  mask-size: 800%;
  -webkit-mask-position: 0;
  mask-position: 0;
}
@keyframes animateBg {
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
}

.changementCouleur:hover {
  transition: mask-position 2s ease,-webkit-mask-position 2s ease;
  -webkit-mask-position: 120%;
  mask-position: 120%;
  opacity: 1;
}