.Footer {
  display: block;
  position: fixed;
  bottom: 50px;
  z-index: 1000;
}

.active {
  border-radius: 9999px;
  background-color: var(--shadow-color);

  box-shadow: 0px 0px 20px var(--shadow-color);
}

.tabs22 {
  color: white;
}

.tabs22:hover {
  border-radius: 9999px;
  background-color: var(--shadow-color);
  color: #0080bc;
}

.sub-footer {
  background: var(--navbar-color);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.shadow_box_footer {
  box-shadow: 0px 0px 20px var(--shadow-color);
}
