:root {
  --my-color: rgb(0, 0, 0);
  --primary-color: #01d09e;
  --secondary-color: #0ecfde;
  --background-color2: rgb(24, 31, 38);
  --primarygradient-color: linear-gradient(
    180deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primarygradient-color-reverse: linear-gradient(
    0deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primaryandsecondarygradient-color: linear-gradient(
    340deg,
    var(--primary-color) 0%,
    var(--secondary-color) 20%,
    var(--primary-color) 40%,
    var(--secondary-color) 60%,
    var(--primary-color) 80%,
    var(--secondary-color) 100%
  );
  --text-color-primary: #eeeeee;
  --text-color-primary-opacity: rgba(228, 234, 239, 0.5);
  --text-color-secondary: #bfbfbf;
  --text-color-troisieme: rgb(140, 140, 140);
  --box-color: rgba(32, 30, 30, 0.8);
  --shadow-color: rgba(203, 213, 225, 0.5);
  --navbar-color: transparent;
  --box-color2: rgb(19, 19, 21);
  --shadowbox-color: 0px 0px 20px rgba(255, 255, 255, 0.25);
  --shadowbox-btn-color: 0px 0px 20px rgba(255, 255, 255, 0.3);
  --logomedia-color-primary: #0ecfde;
  --color-badge: #4b4c52;

  /* or any other variables/style */
}
[data-theme="dark"] {
  --my-color: rgb(0, 0, 0);
  --primary-color: #01d09e;
  --secondary-color: #0ecfde;
  --background-color2: rgb(24, 31, 38);
  --primarygradient-color: linear-gradient(
    180deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primarygradient-color-reverse: linear-gradient(
    0deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primaryandsecondarygradient-color: linear-gradient(
    135deg,
    var(--primary-color) 30%,
    var(--secondary-color) 70%
  );
  --text-color-primary: #eeeeee;
  --text-color-primary-opacity: rgba(228, 234, 239, 0.5);
  --text-color-secondary: #bfbfbf;
  --text-color-troisieme: rgb(140, 140, 140);
  --box-color: rgba(32, 30, 30, 0.8);
  --shadow-color: rgba(203, 213, 225, 0.5);
  --navbar-color: transparent;
  --box-color2: rgb(19, 19, 21);
  --shadowbox-color: 0px 0px 20px rgba(255, 255, 255, 0.25);
  --shadowbox-btn-color: 0px 0px 20px rgba(255, 255, 255, 0.3);
  --logomedia-color-primary: #0ecfde;
  --color-badge: #4b4c52;
}
[data-theme="clear"] {
  --my-color: #eaeaef;
  --primary-color: #01d09e;
  --secondary-color: #0ecfde;
  --background-color2: rgb(201, 201, 201);
  --primarygradient-color: linear-gradient(
    180deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primarygradient-color-reverse: linear-gradient(
    0deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    var(--background-color2) 100%
  );
  --primaryandsecondarygradient-color: linear-gradient(
    135deg,
    var(--primary-color) 30%,
    var(--secondary-color) 70%
  );
  --text-color-primary: #151515;
  --text-color-primary-opacity: rgba(0, 0, 0, 0.5);
  --text-color-secondary: #262626;
  --text-color-troisieme: rgba(55, 65, 81, 1);
  --box-color: rgb(255, 255, 255);
  --shadow-color: rgba(100, 110, 121, 0.5);
  --navbar-color: rgba(156, 163, 170, 0.456) none repeat scroll 0% 0%;
  --box-color2: #f5f6f5;
  --shadowbox-color: 0px 0px 20px rgba(30, 31, 32, 0.5);
  --shadowbox-btn-color: 0px 0px 20px rgba(0, 0, 0, 0.3);
  --logomedia-color-primary: #01d09e;
  --color-badge: #a3a3a3;
}

.App {
  max-width: 100%;
  height: auto;
  overflow-x: hidden;
  background: var(--my-color);
}

main {
  scroll-behavior: smooth;
}

section {
  /* height: 100vh; */
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
}

#homePage {
  background: var(--primarygradient-color);
  width: 100%;
  /* box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.5); */
}

#Portfolio {
  background: var(--primarygradient-color-reverse);
  width: auto;
  margin-right: -40em;
  margin-left: -40em;
  padding-right: 40em;
  padding-left: 40em;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

#Footerend {
  width: 100%;
  min-height: 100%;
  transform: scale(1);
}

#Contact {
  background: var(--primarygradient-color);
}

#Footer {
  background: var(--primarygradient-color-reverse);
  width: 100%;
}

.backend {
  display: flex;
}

.App-logo {
  height: 34px;
  pointer-events: none;
  width: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 16s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  color: var(--text-color-primary);
}
p {
  color: var(--text-color-secondary);
}

.logo_contact {
  color: var(--text-color-primary);
}

.text_color_theme {
  color: var(--text-color-primary);
}

.logocontact_color_theme {
  background: rgb(1, 208, 158);
  background: linear-gradient(
    0deg,
    rgba(1, 208, 158, 1) 0%,
    rgba(1, 208, 158, 1) 15%,
    rgba(14, 207, 222, 1) 100%
  );
}

.logomedia_color_theme {
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* background: rgb(1,208,158); */
  background-image: linear-gradient(
    0deg,
    rgba(1, 208, 158, 1) 0%,
    rgba(14, 207, 222, 1) 100%
  );
}

.logomedia_shadowbox_theme {
  box-shadow: var(--shadowbox-color);
  backdrop-filter: "blur(6px)";
  opacity: 0.85;
}

@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");
.text_font {
  font-family: "Varela Round", sans-serif;
}

.buttom_download {
  color: var(--my-color);
  font-size: large;
  background: rgb(1, 208, 158);
  background-image: linear-gradient(
    0deg,
    rgba(1, 208, 158, 1) 0%,
    rgba(14, 207, 222, 1) 100%
  );
  opacity: 0.8;
  box-shadow: 0px 0px 10px #0ecfde;
}

.contentEducation {
  background-color: var(--background-color2);
  width: auto;
  margin-right: -40em;
  margin-left: -40em;
  padding-right: 40em;
  padding-left: 40em;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  transform: skew(0deg);
}

.bgSkills {
  background: linear-gradient(
    38deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    rgba(14, 207, 222, 0.15) 50%,
    var(--my-color) 60%
  );
}

@media screen and (min-width: 768px) {
  .bgSkills {
    background: linear-gradient(
      30deg,
      var(--my-color) 0%,
      var(--my-color) 40%,
      rgba(14, 207, 222, 0.15) 50%,
      var(--my-color) 60%
    );
  }
}

@media screen and (min-width: 1024px) {
  .bgSkills {
    background: linear-gradient(
      12deg,
      var(--my-color) 0%,
      var(--my-color) 40%,
      rgba(14, 207, 222, 0.15) 50%,
      var(--my-color) 60%
    );
  }
}

@media screen and (min-width: 1536px) {
  .bgSkills {
    background: linear-gradient(
      6deg,
      var(--my-color) 0%,
      var(--my-color) 40%,
      rgba(14, 207, 222, 0.15) 50%,
      var(--my-color) 60%
    );
  }
}

.bgExperiences {
  background: linear-gradient(
    165deg,
    var(--my-color) 0%,
    var(--my-color) 40%,
    rgba(1, 208, 158, 0.15) 50%,
    var(--my-color) 65%
  );
}

.splashScreen{
  background: var(--primarygradient-color);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Créez une animation qui fera disparaître l'élément */
@keyframes fadeOutsplashScreen {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
      display: none;
      z-index: -1;
  }
}

/* Appliquez l'animation à l'élément dès le chargement de la page */
.fade-out-element {
  animation: fadeOutsplashScreen 2s; /* 2s est la durée de l'animation, vous pouvez la modifier selon vos besoins */
  animation-fill-mode: forwards; /* Laissez l'élément à sa valeur finale d'opacité */
}